import React from 'react'

import Carousel from 'react-bootstrap/Carousel'

import RpiLogo from 'images/partners/RPi-Logo-Reg-SCREEN.webp'
import TdkLogo from 'images/partners/TDK-Logo.webp'
import ChinfaLogo from 'images/partners/chinfa-logo.webp'
import TelemecaniqueLogo from 'images/partners/Telemecanique-logo.webp'

import { COMPANY_NAME, FORMATTED_PHONE_NUMBER, PHONE_NUMBER, EMAIL } from 'settings'

function HomePage() {
  return (
    <main className="main" role="main">
      <section className="categories">
        <div className="container-fluid">
          <div className="row align-items-baseline justify-content-center pt-4 pb-4">
            <div className="col">
              <div className="section-title">
                <h1>Электронные компоненты от лидеров мирового рынка.</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 px-lg-0 px-2">
              <div className="categories__item categories__large__item text-light">
                <div className="h-25 bg-dark w-100 mb-0 p-4 title">
                  <h4>Производство печатных плат</h4>
                  <p className="text-start">различные материалы, в том числе семейство Rodgers, от 02 до 50 слоев, прецизионная точность изготовления и
                    позиционирования компонент за счет автоматизированного монтажа, весь спектр диагностических процедур,
                    срок производства от 7 дней.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pr-lg-0 px-2">
              <div className="row ml-0">
                <div className="col-lg-6 pl-0">
                  <div className="categories__item text-light category__3">
                    <div className="bg-dark w-100 text-center mb-0 p-4 title">
                      <h4>Источники питания</h4>
                      <p className="text-start">Широкий спектр миниатюрных DC-DC преобразователей, силовые корректирующие модули,
                        ультра миниатюрные пассивные магнитные компоненты, а также различные миниатюрные блоки питания. Класс качества: индустриальный и военный.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pl-0">
                  <div className="categories__item text-light category__4">
                    <div className="bg-dark w-100 text-center mb-0 p-4 title">
                      <h4>Датчики и тепловидение</h4>
                      <p className="text-start">Датчики токовые, магнитные датчики, в том числе линейные и углового позиционирования.
                        Оригинальные датчики для промышленности, автомобилей, освещения и бытовой электронной техники.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pl-0">
                  <div className="categories__item text-light category__2">
                    <div className="bg-dark w-100 text-center mb-0 p-4 title">
                      <h4>Электромеханические компоненты</h4>
                      <p className="text-start">Представляем широкий ассортимент соединителей и электромеханических
                        компонентов для различных применений от ведущих мировых производителей.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 pl-0">
                  <div className="categories__item text-light category__5">
                    <div className="bg-dark w-100 text-center mb-0 p-4 title">
                      <h4>Пассивные компоненты</h4>
                      <p className="text-start">Электронные компоненты делятся на активные и пассивные.
                        К активным относятся устройства способные усиливать или преобразовывать электрические сигналы.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services spad">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 text-start">
              <h2>Немного о нас</h2>
              <p>{COMPANY_NAME} ваш надежный партнер, поставляющий несколько тысяч товаров крупнейших производителей мира.
                Главной целью компании является обеспечение клиентов широкой номенклатурой электронных
                компонентов со всего мира от ведущих производителей, а также высококлассный сервис и
                деятельность исключительно в интересах партнера.
                Мы гарантируем индивидуальный подход к клиентам, независимо от требований и объемов вашего бизнеса,
                будь то новое имя на рынке, любительское производство или же
                производственный отдел компаний с мировым именем.
              </p>
            </div>
            <div className="col-sm-4">
              <h2>Связаться с нами</h2>
              <address><strong>{COMPANY_NAME}</strong></address>
              <address className="text-start">
                <span>Телефон: </span>
                <a target="blank" href={`tel:+${PHONE_NUMBER}`}>{FORMATTED_PHONE_NUMBER}</a>
                <br />
                <span>Электронная почта: </span>
                <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
              </address>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <Carousel className="carousel slide" data-bs-theme="dark" id="partnersLogos" indicators={false}>
          <Carousel.Item className="carousel-item w-100 text-center">
            <img className="d-block h-100 m-auto" alt="RPi-Logo-Reg-SCREEN.webp" src={RpiLogo} />
          </Carousel.Item>
          <Carousel.Item className="carousel-item w-100 text-center">
            <img className="d-block h-100 m-auto" alt="TDK-Logo.svg.webp" src={TdkLogo} />
          </Carousel.Item>
          <Carousel.Item className="carousel-item w-100 text-center">
            <img className="d-block h-100 m-auto" alt="chinfa-log.webp" src={ChinfaLogo} />
          </Carousel.Item>
          <Carousel.Item className="carousel-item w-100 text-center">
            <img className="d-block h-100 m-auto" alt="Telemecanique-log.webp" src={TelemecaniqueLogo} />
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="services spad">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 mb-3 mb-lg-0">
              <div className="d-flex align-items-center">
                <svg className="bi bi-shield-check align-self-start me-3 flex-shrink-0" fill="red" height="3em" viewBox="0 0 16 16" width="3em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.443 1.991a60.17 60.17 0 0 0-2.725.802.454.454 0 0 0-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197
                    2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 0 0 8 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197
                    10.197 0 0 0 2.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 0 0-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51
                    0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 0 1 2.772.815c.528.168.926.623
                    1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 0 1-2.418 2.3 6.942 6.942 0 0 1-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908
                    6.908 0 0 1-1.007-.586 11.192 11.192 0 0 1-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 0 1 2.415 1.84a61.11 61.11 0 0 1 2.772-.815z" fillRule="evenodd"></path>
                  <path d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" fillRule="evenodd"></path>
                </svg>
                <div className="flex-grow-1 text-start">
                  <h4 className="my-0">Гарантия качества</h4>
                </div>
              </div>
              <p className="my-4 mb-0 text-start">При покупке электронных компонентов с открытого рынка, будь то для собственного производства
                или для перепродажи нестандартные и контрафактные детали представляют большой риск для вашего бизнеса.
                И даже, если чрезмерные расходы на повторную закупку могут быть покрыты и можно компенсировать задержки
                во времени, то ущерб репутации вашей компании никогда не будет устранен.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-3 mb-lg-0">
              <div className="d-flex align-items-center">
                <svg className="bi bi-clipboard-check align-self-start me-3 flex-shrink-0" fill="red" height="3em" viewBox="0 0 16 16" width="3em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1
                    1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" fillRule="evenodd"></path>
                  <path d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5
                    0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3zm4.354 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708
                    0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" fillRule="evenodd"></path>
                </svg>
                <div className="flex-grow-1 text-start">
                  <h4 className="my-0">Контрактное производство</h4>
                </div>
              </div>
              <p className="my-4 mb-0 text-start">Опыт и знания специалистов нашей компании охватывают все этапы жизненного цикла продукта,
                от разработки спецификаций до создания 3D-моделей и моделирования, от проектирования печатных плат до создания прототипов.
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-3 mb-lg-0">
              <div className="d-flex align-items-center">
                <svg className="bi bi-archive align-self-start me-3 flex-shrink-0" fill="red" height="3em" viewBox="0 0 16 16" width="3em" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0
                    0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0
                    1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path>
                </svg>
                <div className="flex-grow-1 text-start">
                  <h4 className="my-0">Поддержание складских остатков под нужды конкретного клиента</h4>
                </div>
              </div>
              <p className="my-4 mb-0 text-start">Наша компания стремится свести к минимуму риски, связанные со своевременным наличием необходимого клиенту товара.
                Для этой цели мы обеспечиваем поддержание достаточных складских остатков электронных компонентов,
                а также конечных изделий под конкретного клиента.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default HomePage
