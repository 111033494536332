import React from 'react'

import Service1Image from 'images/service1.jpg'
import Service2Image from 'images/service2.jpg'
import ManufacturerImage from 'images/manufacturer.jpg'

import { Link as RouterLink } from 'react-router-dom'

import { COMPANY_NAME } from 'settings'

function ServicesPage() {
  return (
    <main className="main" role="main">
      <div className="container text-start pt-3">
        <h1>Услуги</h1>
        <hr />
        <div className="row">
          <div className="col-sm-6">
            <h4>Закупка</h4>
            <p>Мы в {COMPANY_NAME} гордимся нашей всемирной закупочной сетью, которую мы тщательно собирали на протяжении многих лет, что позволяет нам находить труднодоступные и устаревшие компоненты. Наши давние отношения с производителями и дистрибьюторами, а также осведомленность о тенденциях на рынке позволяют нам предоставлять вам самые конкурентоспособные цены и самые короткие сроки доставки. Мы с радостью предложим вам наилучшие условия для ваших закупок</p>
            <p>Свяжитесь с <RouterLink to="/info">{COMPANY_NAME}</RouterLink> и наша команда по закупкам  разработает оптимальное сочетание цены, доступности и качества.</p>
          </div>
          <div className="col-sm-6">
            <h4>Оценка и продажа</h4>
            <p>Для большинства предприятий, продажа активов не является основной компетенцией и лучше доверить это квалифицированным специалистам. {COMPANY_NAME} предоставляет услуги по продаже лишних электронных компонентов, а также устаревшего оборудования и средств электронной коммуникации. Мы будем работать с вами, чтобы определить оптимальное решение для обеспечения рентабельной утилизации и перепродажи ваших активов через нашу международную сеть сбыта, чтобы максимально увеличить ваш доход.</p>
            <p>Вы хотите узнать стоимость перепродажи ваших избыточных компонентов? Команда <RouterLink to="/info">{COMPANY_NAME}</RouterLink> обязательно поможет вам.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <img className="w-100" alt="" src={Service1Image} />
          </div>
          <div className="col-sm-6">
            <img className="w-100" alt="" src={Service2Image} />
          </div>
        </div>
        <hr className="pt-2" />
        <div className="row">
          <div className="col-12">
            <h4>Контрактное производство</h4>
            <p>{COMPANY_NAME} использует передовые технологи по производству электроники, изготовлению образцов и прототипов. Наш опыт в проектировании и создании технических изделий помогает осуществить весь процесс производства, от разработки 3D-моделей до создания реальных прототипов.</p>
            <p>{COMPANY_NAME} также может снизить ваши затраты за счет широкого выбора компонентов, сокращения количества деталей, отладки, оптимизации дизайна и удобства обслуживания. Используя наши знания в других областях, мы можем наладить цепь поставок, разработать процедуры тестирования и контроля качества.</p>
            <img className="w-100 mb-3" alt="" src={ManufacturerImage} />
            <p>Наше сотрудничество может продолжиться и после налаживания процесса производства. {COMPANY_NAME} предоставляет свои услуги  на протяжении всего процесса реализации товара, постоянно анализируя процессы производства и контроля качества, оптимизируя производственный процесс вашего оборудования.</p>
            <p>
              Независимо от того, хотите ли вы наладить массовое производство или же приобрести сделанный на заказ компонент,
              <br />
              в {COMPANY_NAME} у вас всегда есть возможность сократить ваши расходы, снизить риски и ускорить процесс выхода на мировой рынок.
            </p>
            <p>Для получения услуг по изготовлению контрактных изделий, пожалуйста,
              {' '}
              <RouterLink to="/info">свяжитесь с нашими менеджерами.</RouterLink>
              {' '}
              {COMPANY_NAME} обязательно поможет вам снизить стоимость, обеспечить производство качественного продукта и удовлетворить ваши производственные потребности.
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ServicesPage
