import React from 'react'

import { Routes, Route } from 'react-router-dom'

import HomePage from 'containers/Pages/Home';
import ServicesPage from 'containers/Pages/Services';
import ProductsPage from 'containers/Pages/Products';
import QualityPage from 'containers/Pages/Quality';
import InfoPage from 'containers/Pages/Info';

import Header from 'components/Header'
import Footer from 'components/Footer'

import ErrorPage from 'components/ErrorPage'
import NotFoundPage from 'components/NotFoundPage'

import './App.scss';

export const NAV_MENU = [
  {
    path: '/',
    label: 'Главная',
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'services',
    label: 'Услуги',
    element: <ServicesPage />,
  },
  {
    path: '/products',
    label: 'Продукция',
    element: <ProductsPage />,
  },
  {
    path: '/quality',
    label: 'Качество',
    element: <QualityPage />,
  },
  {
    path: '/info',
    label: 'О нас',
    element: <InfoPage />,
  },
]

function App() {
  return (
    <>
      <Header navItems={NAV_MENU} />
      <Routes>
        {NAV_MENU.map(page => (
          <Route key={page.path.replace('/', '')} path={page.path} element={page.element} />
        ))}
        <Route path="/*" element={<NotFoundPage />} />
       </Routes>
      <Footer />
    </>
  );
}

export default App;
