import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { COMPANY_NAME, FORMATTED_PHONE_NUMBER, PHONE_NUMBER } from 'settings'

function Header(props) {
  return (
    <header>
      <div className="header d-fixed">
        <Navbar expand="lg" className="navbar navbar-expand-lg navbar-dark bg-secondary">
          <Container className="p-0">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="phone nav-item me-3">
              <a className="nav-link text-light" target="_blank" href={`tel:+${PHONE_NUMBER}`} rel="noreferrer">
                {FORMATTED_PHONE_NUMBER}
              </a>
            </div>
            <div className="header__logo me-3">
              <Navbar.Brand href="/">
                <div className="navbar_logo">{COMPANY_NAME}</div>
              </Navbar.Brand>
            </div>
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
              <Nav>
                {props.navItems.map(page => (
                  <Nav.Link as={RouterLink} key={page.path} to={page.path}>{page.label}</Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </header>
  )
}

export default Header
