import React from 'react'
import About1Image from 'images/about1.jpg'

import { COMPANY_NAME, EMAIL, FORMATTED_PHONE_NUMBER, PHONE_NUMBER } from 'settings'

function InfoPage() {
  return (
    <main className="main" role="main">
      <div className="container text-start pt-3">
        <h3>О нас</h3>
        <hr />
        <p>{COMPANY_NAME} молодая быстроразвивающаяся компания с большим прошлым, благодаря которому в
          наши дни поставляемая продукция исчисляется несколькими тысячами товаров
          крупнейших производителей мира. Главной целью компании является обеспечение
          клиентов широкой номенклатурой электронных компонентов со всего мира от ведущих производителей.
          Нашими клиентами являются как любители, так и отделы R &amp; D крупных мировых производителей.
          Но несмотря на это мы относимся к каждому нашему клиенту с особым вниманием и индивидуальным подходом.
        </p>
        <img className="w-100 mb-3" alt="" src={About1Image} />
        <p>
          Главные преимущества нашей компании - это способность обеспечить отличный сервис и решения для конкретных клиентов
          при сохранении ценовой конкурентоспособности. {COMPANY_NAME} предаставляет помощь в подборе редких компонентов,
          в том числе снятых с производства. Таким образом наши клиенты приобритают в одном месте по конкурентной стоимости
          высокого качества комплектующие, необходимые для их нужд, выгодно перераспределив ресурсы и время на другие процессы бизнеса.
        </p>
        <p>{COMPANY_NAME} предоставляет высококачественную продукцию и сервис, уделяет каждому клиенту большое количество времени и внимания,
          при этом сохраняет стандарты производства и высокую скорость работы. Каждому клиенту, от инженеров-ремонтников до производителей оборудования,
          мы предоставляем индивидуальный подход и высокую степень заинтересованности со стороны наших сотрудников. Каждый заказ важен для нас.
          Наша компания - надежный партнер, который предлагает комплексные   решения, подходящие к индивидуальным требованиям
          каждого клиента и учитывает  их потребности и предпочтения. Мы предоставляем широкий ассортимент продукции и не устанавливаем
          количество минимального заказа. Мы можем предоставить любой электронный компонент, включая отдельные его элементы.
          Клиенты translation missing: ru.company_details.name
          могут также заказывать компоненты для своих проектов и регистрировать их для получения субсидий на развитие.
        </p>
        <p>
          Наши сотрудники говорят на 5 языках (английском, испанском, китайском, русском и немецком).
          Это позволяет нашей компании предоставлять услуги клиентам по всему миру, избегая недопонимания и языковые барьеры.
        </p>
        <p>
          Если у вас что-то сломано или неисправно, вы должны отправиться к нам. Если у вас есть идея, как сделать мир лучше,
          наша компания - это то, что вам нужно. Кем бы вы ни были, мы найдем правильное решение именно для вас. {COMPANY_NAME}
          гарантирует качественный сервис, лучшие компоненты от надежных производителей и постоянную обратную связь с нашими сотрудниками.
        </p>
        <hr />
        <div id="contacts">
          <div className="row mb-4">
            <div className="col-7 text-start">
              <h2>Еще немного о нас</h2>
              <p>{COMPANY_NAME} ваш надежный партнер, поставляющий несколько тысяч товаров крупнейших производителей мира.
                Главной целью компании является обеспечение клиентов широкой номенклатурой электронных
                компонентов со всего мира от ведущих производителей, а также высококлассный сервис и
                деятельность исключительно в интересах партнера.
                Мы гарантируем индивидуальный подход к клиентам, независимо от требований и объемов вашего бизнеса,
                будь то новое имя на рынке, любительское производство или же
                производственный отдел компаний с мировым именем.
              </p>
            </div>
            <div className="col-5 text-right">
              <h2>Связаться с нами</h2>
              <div><strong>{COMPANY_NAME}</strong></div>
              <span>Телефон: </span>
              <a target="blank" href={`tel:+${PHONE_NUMBER}`}>{FORMATTED_PHONE_NUMBER}</a>
              <br />
              <span>Электронная почта: </span>
              <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </div>
            <div className="col-sm-12">
              <div id="map">
                <div style={{ position: 'relative', overflow: 'hidden' }}>
                  <a href="https://yandex.by/maps/12/smolensk/?utm_medium=mapframe&utm_source=maps" style={{ color:"#eee", fontSize: 12, position: "absolute", top: 0 }}>
                    Смоленск
                  </a>
                  <a href="https://yandex.by/maps/12/smolensk/house/ulitsa_kashena_1/Z0sYdgNmTUYAQFtufXt4dXVnYA==/?indoorLevel=1&ll=32.046437%2C54.794944&utm_medium=mapframe&utm_source=maps&z=17" style={{ color: "#eee", fontSize: 12, position: 'absolute', top: 14 }}>
                    Улица Кашена, 1 на карте Смоленска — Яндекс Карты
                  </a>
                  <iframe
                    title="YMap"
                    src="https://yandex.by/map-widget/v1/?indoorLevel=1&ll=32.046437%2C54.794944&mode=whatshere&whatshere%5Bpoint%5D=32.046437%2C54.794944&whatshere%5Bzoom%5D=17&z=17"
                    width="100%"
                    height="400"
                    allowFullScreen={true}
                    style={{ position: 'relative' }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default InfoPage
