import React from 'react'

import styles from './styles.module.scss'

export default function ErrorPage() {
  return (
    <div id="error-page" className={styles.container}>
      <h1>Oops!</h1>
      <p>The requested page was not found</p>
    </div>
  );
}
