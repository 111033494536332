import React from 'react'

import { COMPANY_NAME } from 'settings'

function QualityPage() {
  return (
    <main className="main" role="main">
      <div className="container text-start pt-3">
        <h3>Качество</h3>
        <hr />
        <p>
          Покупая компоненты с открытого рынка, будь то для собственного производства или для перепродажи,
          вы знаете, насколько большой риск представляет для вашего бизнеса обнаружение некачественной или контрафактной продукции.
          Несмотря на то, что можно выплатить большие затраты на повторную закупку, можно компенсировать задержки на рынке,
          но ущерб репутации вашей компании никогда не может быть восстановлен. {COMPANY_NAME} устраняет эти риски,
          используя наиболее надежные лаборатории тестирования электронных компонентов среди дистрибьюторов на рынке.
          Эти лаборатории находятся используют передовые исследования и технологии для обнаружения поддельной или некачественной продукции,
          их сертификаты включают ISO 9001, ISO 17025, ANSI / ESD-S20.20
        </p>
        <p>Ниже приведено несколько способов, благодаря которым мы убеждаемся, что ваш бизнес в безопасности от поддельных компонентов:</p>
        <ul>
          <li>
            <h4>Визуальный осмотр</h4>
            <p>Внешний осмотр - это процесс проверки внешних свойств деталей, таких как: проверка общего состояния компонентов, маркировки деталей, наличия вторичного покрытия, соответствия размерам и качеству. Для получения достоверных результатов визуальный осмотр выполняется в соответствии с SAE AS5553 и MIL-STD-883 Method 2009.9.</p>
          </li>
          <li>
            <h4>Рентгеновское тестирование</h4>
            <p>Рентгеновский контроль является внутренним, но не повреждает объект исследования.
              Изображение, полученное с помощью рентгеновского тестирования, позволяет проверять соединения проводов,
              размер матрицы и повреждения, вызванные электростатическими разрядами.
              Подделка может быть обнаружена путем сравнения изображения матрицы этой детали с матричной структурой подлинного устройства.
            </p>
          </li>
          <li>
            <h4>Тестирование на наличие свинца(ROHS test)</h4>
            <p>
              ROHS представляет собой длинноволновой дисперсионный рентгеновский флeоресцентный спектрометр.
              Это позволяет обнаружить свинец в электронных компонентах.
            </p>
          </li>
          <li>
            <h4>Тест функциональности</h4>
            <p>
              Функции компонентов тестируются в соответствии с техническими характеристиками и паспортом продукта,
              используя соответствующую систему оценивания. Мы тщательно проверяем все функции компонента, и если он прошел тест,
              то компонент считается полностью функциональным. Этот тест позволяет определить пригодность компонента для использования.
            </p>
          </li>
          <li>
            <h4>Вскрытие</h4>
            <p>
              Вскрытие - это процесс удаления упаковки компонента или его части для выявления неполадки.
              Этим методом можно проверить компонент на наличие различных повреждений.
            </p>
          </li>
          <li>
            <h4>Подготовка к отправке</h4>
            <p>
              Перед отправкой продукты герметично упаковываются для повышения их безопасности в процессе отгрузки,
              а также, чтобы обеспечить соответствие стандартам MSL.
            </p>
          </li>
        </ul>
      </div>
    </main>
  )
}

export default QualityPage
