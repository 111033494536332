import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { COMPANY_NAME, FORMATTED_PHONE_NUMBER, PHONE_NUMBER, EMAIL, LEGAL_ADDRESSES } from 'settings'

function Footer() {
  return (
    <footer className="footer mt-auto bg-light border-top pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-12">
            <div className="footer__about pb-md-0 pb-3">
              <small className="text-muted">Развивайте свой бизнес с</small>
              <div className="footer__logo">
                <RouterLink className="text-body" to="/">
                  <h4>{COMPANY_NAME}</h4>
                </RouterLink>
              </div>
            </div>
            <address className="text-start text-muted">
              {LEGAL_ADDRESSES.map((address, index) => (
                <p key={index}>
                  {address}
                </p>
              ))}
              <p></p>
            </address>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <address className="text-end text-muted">
              <p>Телефон: <a target="blank" href={`tel:+${PHONE_NUMBER}`}>{FORMATTED_PHONE_NUMBER}</a></p>
              <p>Электронная почта: <a href={`mailto:${EMAIL}`}>{EMAIL}</a></p>
              <p></p>
            </address>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="footer__copyright__text">
              <small className="text-muted">
                <span className="me-3">Copyright © {new Date().getFullYear()}</span>
                <b>{COMPANY_NAME}</b>
              </small>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
